import de from 'date-fns/locale/de';
import { DefaultConfig } from './types';
import { defaultConfig } from './default';

export const defaultJuniper: DefaultConfig = {
  ...defaultConfig,
  brand: 'juniper-de',
  currency: 'EUR',
  currencyLocales: 'de-DE',
  country: 'Germany',
  requireInternationalClinicianPhone: true,
  fulfilmentVendors: ['apons', 'pluripharm'],
  defaultTimezone: 'Europe/Berlin',
  dateFnsLocale: de,
  isDigitalSigningEnabled: true,
};
