import { defaultJuniper } from './default-juniper-de';
import { sharedDevEuEnv } from './shared';
import { Config } from './types';

export const config: Config = {
  ...defaultJuniper,
  ...sharedDevEuEnv,
  environment: 'development',
  apiUrl: 'https://api.admin.eu.dev.eucalyptus.vc',
  restApiUrl: 'https://api.admin.dev.de.myjuniper.com',
  assetsUrl:
    'https://storage.googleapis.com/euc-services-dev-juniper-de-assets',
  logoUrl: 'https://assets.dev.myjuniper.co.uk/juniper-logo.svg', // copied from UK
  shopifyHostname: undefined,
  userAppUrl: 'https://app.dev.de.myjuniper.com',
  doctorsAppUrl: 'https://prescribers.dev.de.myjuniper.com',
  googleOAuthClientId:
    '966685249037-0ikbs91jfl2gns80jlrsai618ak8smp9.apps.googleusercontent.com',
  quizAdminUrl: 'https://quiz-admin.dev.de.myjuniper.com',
  launchDarklyClientId: '6062ab38c8369f0e6d6c5ee4',
  planIntervals: ['Weekly'],
};
